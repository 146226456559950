@import "../../assets/styles/variables.scss";

.form-control {
   position: relative;
   margin-bottom: 30px;
   color: $blue;

   &__input {
      width: 100%;
      min-height: 58px;
      padding: 18px 16px 16px 16px;
      background-color: $dark-blue;
      color: $white;
      border: 1px solid $blue;
      border-radius: 3px;
      outline: none;
      resize: none;
      transition: box-shadow 0.15s linear;

      &--number {
         &::-webkit-outer-spin-button,
         &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
         }
      }

      &--high {
         height: 280px;
      }

      &:hover,
      &:focus {
         box-shadow: 0 0 20px 0 rgba($blue, 0.5);
      }

      &:hover,
      &:focus {
         + .label {
            &:before {
               box-shadow: 0 0 20px 0 rgba($blue, 0.5);
            }
         }
      }
   }

   &__label {
      position: absolute;
      top: 21px;
      left: 16px;
      color: $white;
      pointer-events: none;
      transition: top 0.15s linear, background 0.15s linear, color 0.15s linear,
         padding 0.15s linear, font 0.15s linear;

      &--full {
         top: -10px;
         padding: 4px 8px;
         background-color: $dark-blue;
         font-size: 14px;
         line-height: 14px;
         color: inherit;
         border-radius: 4px;

         &::before {
            content: "";
            position: absolute;
            left: 0;
            z-index: -1;
            border-radius: 4px;
            width: 100%;
            height: 16px;
            transition: box-shadow 0.15s linear;
         }
      }
   }

   &__helper {
      display: block;
      margin-top: 10px;
      font-size: 14px;
      line-height: 16px;
      color: inherit;
      transition: color 0.15s linear;

      &--small {
         font-size: 12px;
         line-height: 14px;
      }
   }

   &--error {
      .form-control__helper {
         &:first-of-type {
            color: $red;
         }
      }

      .form-control__input {
         border-color: $red;

         &:hover,
         &:focus {
            box-shadow: none;
         }

         &:hover,
         &:focus {
            + .label {
               &:before {
                  box-shadow: none;
               }
            }
         }
      }
   }
}
