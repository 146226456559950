@import "./../../assets/styles/variables.scss";

.title {
   margin: 45px 0 30px;
   font-weight: 500;
   font-size: 26px;
   line-height: 38px;
   letter-spacing: -0.01em;
   color: $white;
   text-shadow: 0 0 25px $blue;
}
