@import "./../../assets/styles/variables";

.mini-game-table {
   display: flex;
   max-width: 520px;
   width: 100%;
   margin: 40px auto 0;
   font-size: 14px;
   line-height: 16px;
   background-color: $gray-blue;
   border: 1px solid #3a3053;
   border-radius: 3px;
   text-align: center;

   &__row {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 50%;

      &:not(:last-child) {
         border-right: 1px solid #3a3053;
      }
   }

   &__header,
   &__footer,
   &__content {
      display: flex;
      min-height: 56px;
   }

   &__content {
      flex-grow: 1;
      padding: 20px;
   }

   &__header {
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #3a3053;
   }

   &__footer {
      justify-content: center;
      align-items: center;
      border-top: 1px solid #3a3053;
      text-align: center;
   }

   &__title {
      font-weight: 500;
      letter-spacing: -0.01em;
      text-shadow: 0 0 25px $blue;
   }

   &__text {
      color: rgba($light-purple, 0.8);
      white-space: nowrap;
   }

   &__player {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;

      &-turns {
         margin-top: 20px;
      }

      &-info {
         font-weight: 900;
         font-size: 24px;
         line-height: 38px;
         text-shadow: 0 0 25px $blue;
      }

      &-turn {
         position: relative;
         width: 25px;
         height: 22px;
         font-weight: 500;
         font-size: 18px;
         line-height: 21px;
         letter-spacing: -0.01em;
         text-shadow: 0 0 25px $blue;

         &:not(:last-child) {
            margin-bottom: 20px;
         }

         #rectangle-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 16px;
            height: 16px;
            filter: drop-shadow(0 0 15px $blue);
            transform: translate(-50%, -50%);
         }

         #polygon-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 22px;
            height: 17px;
            filter: drop-shadow(0 0 15px $orange);
            transform: translate(-50%, -50%);
         }
      }
   }
}
