@import "./../../assets/styles/variables.scss";

.checkbox {
  &__item {
    display: none;

    &:checked ~ .checkbox__indicator .checkbox__checker {
      opacity: 1;
    }
  }

  &__label {
    position: relative;
    padding-left: 32px;
    line-height: 40px;
    color: $blue;
    text-shadow: 0 0 25px $blue;
    transition: color 0.15s linear;
    cursor: pointer;
    user-select: none;
    outline: none;

    &:hover,
    &:focus {
      color: $glow;
    }
  }

  &__indicator {
    position: absolute;
    width: 14px;
    height: 14px;
    border: 1px solid $blue;
    border-radius: 3px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__checker {
    position: absolute;
    left: 2px;
    width: 10px;
    height: 7px;
    transform: rotate(-45deg);
    opacity: 0;
    transition: opacity 0.1s linear;

    &::after,
    &::before {
      content: "";
      position: absolute;
      background-color: $blue;
      border-radius: 50px;
    }

    &::after {
      width: 2px;
      height: 7px;
      left: 0;
      top: 0;
    }

    &::before {
      width: 12px;
      height: 2px;
      bottom: 0;
      left: 0;
    }
  }
}