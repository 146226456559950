@import "src/assets/styles/variables";

@media screen and (orientation: landscape) and (max-width: 1024px) {
  .checkbox {
    &__label {
      font-size: 13px;
      line-height: 28px;
    }
  }

  .left-side {
    padding: 10px 15px 30px;

    &__end {
      font-size: 20px;
      line-height: 30px;
    }

    &__info {
      font-size: 13px;
      line-height: 18px;
    }

    &__text {
      margin: 0 0 24px;
      font-size: 13px;
      line-height: 16px;
    }

    &__container {
      overflow-y: scroll;
    }
  }

  .right-side {
    overflow-y: scroll;

    &--dna {
      overflow-y: hidden;
    }

    &__golo-img {
      top: calc(50% - 70px);
      width: 42%;
    }

    &__golo-table {
      width: 52%;

      &--red {
        left: calc(50% + 15px);
      }
    }

    &__grant {
      max-width: 220px;
      margin: 60px auto 0;
      padding: 15px 20px;

      svg {
        width: 35px;
        height: 35px;
      }

      &-title {
        margin: 0 0 6px 0;
        font-size: 13px;
        line-height: 15px;
      }

      &-info {
        margin: 0 0 0 10px;
        font-size: 26px;
        line-height: 38px;
      }
    }
  }

  .btn {
    &-primary {
      max-width: 150px;
      height: 40px;
      min-height: 40px;
      font-size: 13px;
      line-height: 15px;
    }

    &-link {
      padding: 10px 0;
      font-size: 13px;
      line-height: 15px;

      &--socials {
        svg {
          max-width: 14px;
          height: 14px;
        }

        span {
          margin: 0 0 0 6px;
        }
      }
    }

    &-game {
      max-width: 78px;
      height: 30px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    &-call-control {
      margin: 0 0 20px;

      &--phone {
        width: 40px;
        height: 40px;
      }

      &__phone {
        margin: 0;
        font-size: 10px;
        line-height: 16px;
      }

      &__text {
        margin: 6px 0 0 0;
        font-size: 7px;
        line-height: 10px;
      }
    }

    &-call {
      width: 100%;
      padding: 0 0 0 50px;
      font-size: 14px;
      line-height: 21px;

      &:not(:last-child) {
        margin: 0 0 20px;
      }

      &:before {
        width: 36px;
        height: 36px;
      }
    }

    &-close {
      width: 30px;
      height: 30px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .intro {
    &__title {
      font-size: 56px;
      line-height: 68px;
      margin: 0 0 30px 0;
    }

    &__start {
      display: flex;
      align-items: center;
      padding: 0 50px;
    }

    &__btn {
      margin: 0 0 0 60px;
    }

    &__wall {
      top: -20px;
    }
  }

  .audio {
    align-items: center;

    &__btn {
      svg {
        width: 32px;
        height: 32px;
      }
    }

    &__subtitles {
      display: none;
    }

    &__progressbar {
      &-cover,
      &:before {
        height: 4px;
        border-radius: 50px;
      }

      &-thumb {
        width: 14px;
        height: 14px;
      }
    }

    &__timer {
      font-size: 13px;
      line-height: 15px;
    }
  }

  .form-control {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &__input {
      padding: 12px 16px 10px;
      height: 40px;
      min-height: auto;
      font-size: 13px;
      line-height: 15px;
    }

    &__textarea {
      height: auto;
    }

    &__label {
      top: 14px;
      font-size: 13px;
      line-height: 15px;

      &--full {
        top: -8px;
        padding: 0 6px;
      }
    }

    &__helper {
      margin-top: 6px;
      font-size: 13px;
      line-height: 15px;
    }
  }

  .speaker {
    max-width: 86px;
    height: 72px;

    &-gif {
      top: 8px;
      left: 8px;
      width: 68px;
      height: 68px;
    }
  }

  .title {
    margin: 20px 0;
    font-size: 16px;
    line-height: 21px;
  }

  .info {
    &__list {
      padding: 20px 30px 0;
    }

    &__item {
      font-size: 13px;
      line-height: 18px;

      &:not(:last-child) {
        margin-right: 35px;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &__text {
      margin: 0 0 0 6px;
    }
  }

  .quiz-list {
    &__label {
      padding-left: 28px;
      font-size: 13px;
      line-height: 30px;
    }

    &__item {
      &:not(:last-child) {
        margin: 0;
      }
    }
  }

  .mini-game {
    margin: 0 auto 0;
    padding: 0 15px 15px;

    &__title {
      margin: 0 0 10px 0;
    }

    &__text {
      font-size: 13px;
      line-height: 18px;
    }

    &__btn-group {
      margin: 16px auto 0;

      .btn-game {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .mini-game-table {
    margin: 15px auto 0;

    &__header,
    &__footer {
      min-height: 28px;
    }

    &__header {
      font-size: 10px;
      line-height: 12px;
    }

    &__content {
      padding: 8px;
    }

    &__text {
      font-size: 10px;
      line-height: 12px;
      text-align: center;
    }

    &__player-info {
      font-size: 12px;
      line-height: 18px;
    }

    &__player-turns {
      margin: 8px 0 0 0;
    }

    &__player-turn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 14px;

      #polygon-icon {
        width: 14px;
        height: 10px;
      }

      #rectangle-icon {
        width: 10px;
        height: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  .calls {
    margin: 50px auto 0;

    &--incoming {
      max-width: 172px;
      margin: 45px auto 0;
    }

    &__icon {
      margin: 0 0 12px;

      svg {
        width: 45px;
        height: 45px;
      }
    }

    &__btn-control {
      svg {
        width: 20px;
        height: 20px;
      }

      span:first-child {
        height: 25px;
      }
    }

    &__timer {
      margin: 4px 0 28px;
      font-size: 10px;
      line-height: 16px;
    }

    &__title {
      margin: 0 0 24px;
      font-size: 14px;
      line-height: 21px;

      &--incoming {
        margin: 0;
      }
    }
  }
}

@media screen and (orientation: portrait) and (max-width: 1024px) {
  .App {
    position: relative;
    overflow: hidden;

    &:after,
    &:before {
      content: "";
      position: absolute;
      z-index: 3000;
      width: 100%;
      height: 100vh;

    }

    &:before {
      background-color: $dark-blue;
    }

    &:after {
      background-image: url("./../../assets/images/icons/smartphone.svg");
      background-repeat: no-repeat;
      background-size: 100px;
      background-position: center;
      transform: rotate(-45deg);
      animation-name: rotating;
      animation-iteration-count: infinite;
      animation-duration: 4s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
    }
  }
}
