@import "../../assets/styles/variables";

.speaker {
  background-color: $dark-blue;
  &-gif {
    position: absolute;
    top: 18px;
    left: 20px;
    width: 148px;
    height: 148px;
    mix-blend-mode: exclusion;
  }
}