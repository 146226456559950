.game {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;

  &--pos {
    position: fixed;
  }

  &__element {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.prx-container {
  overflow: hidden;
}

.prx-dna-bg {
  position: absolute;
  right: -15px;
  bottom: 0;

  img {
    max-width: 1017px;
    width: 100%;
    height: auto;
  }
}

.prx-dna {
  position: absolute;
  top: 0;
  right: -30px;

  img {
    max-width: 537px;
    width: 100%;
    height: auto;
  }
}