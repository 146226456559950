@import "../../assets/styles/variables";

.audio {
   position: relative;
   display: flex;
   width: 100%;

   &__player {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
   }

   &__subtitles {
      display: flex;
      flex-grow: 1;
      font-size: 13px;
      line-height: 18px;
      color: rgba($white, 0.5);
      text-shadow: 0 0 25px $blue;

      &-container {
         display: flex;
         flex-grow: 1;
         align-items: flex-end;
      }
   }

   &__subtitle {
      position: relative;
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 80px;
      margin-bottom: 15px;
      overflow-y: hidden;

      &-container {
         margin-bottom: 20px;
      }

      &:before,
      &:after {
         content: "";
         position: absolute;
         top: 0;
         width: 100%;
         height: 30px;
         background-image: linear-gradient(180deg, #0E0327 0%, rgba(14, 3, 39, 0) 100%);
      }

      &:after {
         top: auto;
         bottom: 0;
         transform: rotate(-180deg);
      }
   }

   &__timer {
      margin-right: 4px;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      opacity: 0.5;

      &:last-of-type {
         margin-right: 0;
      }
   }

   &__control {
      display: flex;
      align-items: center;
   }

   &__progressbar {
      position: relative;
      width: 100%;
      margin: 0 20px;

      &-cover {
         position: absolute;
         top: 50%;
         z-index: 1;
         width: 0;
         height: 2px;
         background-color: #00E0FF;
         transform: translateY(-50%);
         user-select: none;
         pointer-events: none;
      }

      &-thumb {
         position: absolute;
         top: 50%;
         z-index: 3;
         width: 10px;
         height: 10px;
         background-color: #00E0FF;
         border-radius: 50%;
         transform: translateY(-50%);
         pointer-events: none;
         user-select: none;
      }

      &-range {
         width: 100%;
         height: 10px;
         margin: 0 auto;
         cursor: pointer;
         opacity: 0;
         background-color: #00E0FF;
         -webkit-appearance: none;

         &::-webkit-slider-thumb {
            width: 10px;
            height: 10px;
            background: #00E0FF;
            border-radius: 50%;
            cursor: pointer;
            -webkit-appearance: none;
         }
      }

      &:before {
         content: '';
         position: absolute;
         top: 50%;
         width: 100%;
         height: 2px;
         background-color: #FFFFFF;
         transform: translateY(-50%);
         opacity: 0.25;
      }
   }
}