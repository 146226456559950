@import "./../../assets/styles/variables";

.right-side {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  background-color: $dark-blue;
  overflow-y: auto;

  &__golo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    margin: auto;

    &-anim {
      width: 100%;
      height: 100%;
      animation: float 1.5s infinite alternate;
    }

    &-img {
      position: absolute;
      top: calc(50% - 250px);
      left: 50%;
      transform: translate(-50%);

      &--red {
        top: 0%;
      }
    }

    &-table {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);

      &--red {
        left: calc(50% + 40px);
        transform: translateX(-50%);
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__grant {
    max-width: 380px;
    width: 100%;
    margin: 165px auto 0;
    padding: 40px;
    background-color: $gray-blue;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;

    &-title {
      margin: 0 0 10px 0;
      color: $blue;
      font-size: 20px;
      line-height: 23px;
      text-shadow: 0 0 25px $blue;
    }

    svg {
      width: 50px;
      height: 50px;
    }

    &-info {
      margin: 0;
      font-weight: 500;
      font-size: 48px;
      line-height: 38px;
      letter-spacing: -0.01em;
      filter: drop-shadow(0 0 10px $blue);
    }
  }

  &--gray-blue {
    background-color: $gray-blue;
  }

  &--room {
    background-image: url("./../../assets/images/room.jpg");
  }

  &--blur {
    background-image: url("./../../assets/images/blur.png");
  }

  &--dna {
    overflow: hidden;
    background-image: url("./../../assets/images/dna.jpg");
  }

  &--laboratory {
    background-image: url("./../../assets/images/laboratory.jpg");
  }

  &--investors {
    background-image: url("./../../assets/images/investors.jpg");
  }

  &--investors2 {
    background-image: url("./../../assets/images/investors-2.jpg");
  }

  &--blur-investors {
    background-image: url("./../../assets/images/blur-investors.png");
    background-color: $gray-blue;
  }

  &--building {
    background-image: url("./../../assets/images/building.jpg");
  }

  &--final {
    background-image: url("./../../assets/images/final.jpg");
  }
}