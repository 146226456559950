@import "./libs/normalize";
@import "./utils";
@import "./fonts";

body,
html, {
  overflow: hidden;
}

body {
  background-color: $dark-blue;
  color: $white;
  font-family: "Roboto", "Arial", sans-serif;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

@import "./animation.scss";
@import "./media.scss";
