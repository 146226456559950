@import "src/assets/styles/variables";

.App {
  display: flex;
  min-height: 100vh;

  &__agreement {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba($dark-blue, 0.6);
    overflow: hidden;

    &-block {
      max-width: 830px;
      margin: 0 15px;
      padding: 40px;
      border: 1px solid #FFFFFF;
      border-radius: 5px;
    }

    &-text {
      margin: 0 0 5px;
      font-size: 16px;
      line-height: 24px;
      text-shadow: 0 0 15px $blue;
    }
  }
}