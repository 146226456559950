@import "./../../assets/styles/variables.scss";

.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  border: none;
  line-height: 0;
  text-decoration: none;
  outline: none;

  &[disabled] {
    opacity: 0.8;

    &:hover,
    &:focus {
      box-shadow: 0 0 20px rgba($blue, 0.5);
    }
  }

  &-close {
    position: absolute;
    z-index: 900;
    top: 0;
    right: 0;
    width: 65px;
    height: 65px;
    font-size: 0;
    transition: filter 0.15s linear, stroke 0.15s linear;

    &:hover,
    &:focus {
      filter: drop-shadow(0 0 15px $blue);

      path {
        stroke: $glow;
      }
    }
  }

  &-primary {
    max-width: 210px;
    width: 100%;
    min-height: 62px;
    margin-top: 30px;
    background-color: $blue;
    color: $dark-blue;
    border-radius: 3px;
    font-size: 18px;
    line-height: 26px;
    box-shadow: 0 0 20px rgba($blue, 0.5);
    transition: box-shadow 0.15s linear, opacity 0.15s linear;

    &:hover,
    &:focus {
      box-shadow: 0 0 30px $blue, inset 0 0 20px #b1fffa;
    }
  }

  &-scroll {
    color: #ffe8cd;

    span {
      margin-top: 16px;
      line-height: 24px;
    }
  }

  &-link {
    padding: 8px 0;
    font-size: 16px;
    line-height: 24px;
    color: $blue;
    text-shadow: 0 0 25px $blue;
    text-align: left;
    transition: color 0.15s linear;

    &:hover,
    &:focus {
      color: $glow;
    }

    &--socials {
      display: flex;
      flex-direction: row;
      color: $white;

      span {
        margin-left: 14px;
      }

      &:not(:last-child) {
        margin-right: 50px;
      }
    }
  }

  &-audio-control {
    transition: filter 0.15s linear, stroke 0.15s linear;

    &:hover,
    &:focus {
      filter: drop-shadow(0 0 15px $blue);

      path {
        stroke: $glow;
      }
    }
  }

  &-game {
    max-width: 120px;
    width: 100%;
    height: 62px;
    background-color: transparent;
    border-radius: 3px;
    transition: background 0.15s linear;
    outline: none;

    &--orange {
      border: 1px solid $orange;
      filter: drop-shadow(0 0 10px $orange);

      path,
      circle,
      rect {
        stroke: $orange;
        transition: stroke 0.15s linear;
      }

      &:hover,
      &:focus {
        background-color: $orange;

        path,
        circle,
        rect {
          stroke: $gray-blue;
        }
      }
    }

    &--blue {
      border: 1px solid $blue;
      filter: drop-shadow(0 0 10px $blue);
      stroke: $blue;

      path,
      circle,
      rect {
        stroke: $blue;
        transition: stroke 0.15s linear;
      }

      &:hover,
      &:focus {
        background-color: $blue;

        path,
        circle,
        rect {
          stroke: $gray-blue;
        }
      }
    }

    &--red {
      border: 1px solid $red;
      filter: drop-shadow(0 0 10px $red);
      stroke: $red;

      path,
      circle,
      rect {
        stroke: $red;
        transition: stroke 0.15s linear;
      }

      &:hover,
      &:focus {
        background-color: $red;

        path,
        circle,
        rect {
          stroke: $gray-blue;
        }
      }
    }
  }

  &-call {
    position: relative;
    align-items: flex-start;
    padding: 18px 0 18px 100px;
    font-weight: 500;
    font-size: 26px;
    line-height: 38px;
    letter-spacing: -0.01em;
    color: $white;
    text-shadow: none;

    &-control {
      margin-bottom: 40px;

      &__text {
        margin-top: 15px;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.25;
      }

      &__phone {
        margin-top: 4px;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: -0.01em;
        color: $white;
        opacity: 0.25;
      }

      &--phone {
        width: 90px;
        height: 90px;
        background-color: $red;
        border-radius: 50%;
        filter: drop-shadow(0 0 15px $red);
        transition: box-shadow 0.15s linear, filter 0.15s linear;

        &:hover,
        &:focus {
          box-shadow: inset 0 0 10px #ff8761;
          filter: drop-shadow(0 0 25px $red);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 70px;
      height: 70px;
      background-image: url("./../../assets/images/icons/user.svg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
  }

  &-small {
    display: flex;
    align-items: flex-start;
    max-width: 200px;
  }
}
