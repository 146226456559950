// MIXINS

@mixin font-face($font-name, $path, $weight: 400, $style: normal) {
   @font-face {
      font-display: swap;
      font-weight: $weight;
      font-family: quote($font-name);
      font-style: $style;
      src: url(#{$path}.woff) format("woff"),
         url(#{$path}.woff2) format("woff2");
   }
}
