@import "./../../assets/styles/variables.scss";

.info {
   &__text {
      margin: 0 0 0 8px;
      font-weight: 500;
   }

   &__item {
      display: flex;
      align-items: center;
      filter: drop-shadow(0 0 10px $blue);

      &:not(:last-child) {
         margin-right: 50px;
      }
   }

   &__list {
      display: flex;
      justify-content: flex-end;
      padding: 30px 50px 0;
      margin: 0;
   }
}
