@import "src/assets/styles/variables";

.left-side {
  position: relative;
  display: flex;
  width: 50%;
  height: 100%;
  padding: 50px 15px;
  background-color: $dark-blue;
  overflow-y: auto;

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 540px;
    margin: 0 auto;
  }

  &__error {
    font-size: 14px;
    line-height: 16px;
    color: $red;
  }

  &__socials {
    display: flex;
    margin-top: auto;
  }

  &__end {
    font-size: 30px;
    line-height: 40px;
    margin: 20px 0;
    padding: 20px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 5px;
    text-transform: uppercase;
  }

  &__info {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 24px;
    text-shadow: 0 0 15px $blue;

    &--final {
      font-size: 20px;
      line-height: 30px;

      &:not(:last-of-type) {
        margin-top: 40px;
      }
    }
  }

  &__text {
    margin-bottom: 40px;
    text-shadow: 0 0 15px $blue;
    line-height: 24px;

    span {
      display: block;

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
}
