// VARIABLES

// Colors
$glow: #91f2ff;
$blue: #00e0ff;
$dark-blue: #0e0327;
$gray-blue: #251d39;
$light-purple: #8c81a7;
$white: #ffffff;
$orange: #ffb800;
$red: #ff3263;

$parallax__layers: 16;
