.calls {
  max-width: 260px;
  margin: 120px auto 0;

  &__title {
    margin: 0 0 45px 0;

    &--incoming {
      margin: 0;
      text-shadow: none;
    }
  }

  &__control {
    width: 100%;
  }

  &__icon {
    margin-bottom: 24px;

    svg {
      width: 98px;
      height: 98px;
    }
  }

  &__timer {
    margin-top: 10px;
    margin-bottom: 60px;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.01em;
    opacity: 0.25;
  }

  &__incoming {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__btn-control {
    width: 100%;
    display: flex;
    justify-content: space-between;

    span {
      &:first-child {
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &--incoming {
    max-width: 335px;
    width: 100%;
    margin: 155px auto 0;
  }
}
