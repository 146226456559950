@import "./../../assets/styles/variables.scss";

.quiz-list {
   margin: 0;
   padding: 0;
   list-style: none;

   &__item {
      &:not(:last-child) {
         margin-bottom: 12px;
      }
   }
}
