@keyframes float {
  from {
    will-change: unset;
    transform: translateY(-10px)
  }
  to {
    will-change: transform;
    transform: translateY(-30px)
  }
}

@keyframes rotating {
  0% {
    opacity: 0;
    transform: rotate(-45deg);
  }

  40% {
    opacity: 1;
  }

  50% {
    transform: rotate(45deg);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg);
  }
}