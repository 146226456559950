@import "./../../assets/styles/variables";

.intro {
  width: 100%;

  .dark {
    opacity: 0;
    transition: opacity 0.5s linear;
  }

  .fade {
    opacity: 1;
  }

  &__game {
    padding: 14% 0 5%;
    height: 100%;
    background-color: $dark-blue;
  }

  &__start {
    max-width: 900px;
    margin: 0 auto;
  }

  &__parallax {
    > div {
      pointer-events: none;
    }
  }

  &__parallax-info {
    pointer-events: all;
  }

  &__bottom {
    pointer-events: all;
  }

  &__btn {
    margin-left: 190px;
  }

  &__title {
    margin: 0 0 50px 0;
    font-weight: 400;
    font-size: 80px;
    line-height: 98px;
    font-family: "Impact", "Arial", sans-serif;
    color: #ffe8cd;
    text-align: center;
    letter-spacing: -0.025em;
    text-transform: uppercase;

    span {
      display: block;
    }
  }

  &__city {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__building2 {
    position: absolute;
    bottom: -25%;
    left: 0;
    max-width: 194px;
    width: 12%;
  }

  &__wall {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__tree-top {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 356px;
    width: 20%;
  }

  &__garden {
    position: absolute;
    bottom: -5%;
    right: 0;
    max-width: 1443px;
    width: 76%;
  }

  &__roof {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 1468px;
    width: 78%;
  }

  &__building1 {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 790px;
    width: 42%;
  }

  &__highway {
    position: absolute;
    right: 0;
    bottom: 12%;
    width: 100%;
  }

  &__facebook {
    position: absolute;
    top: 15%;
    left: 2%;
    max-width: 1374px;
    width: 72%;
  }

  &__google {
    position: absolute;
    top: -8%;
    right: 4%;
    max-width: 1500px;
    width: 80%;
  }

  &__abp {
    position: absolute;
    top: -4%;
    right: 0;
    max-width: 600px;
    width: 32%;
  }

  &__costel {
    position: absolute;
    top: -8%;
    right: 0;
    max-width: 407px;
    width: 22%;
  }

  &__tiktok {
    position: absolute;
    top: -2%;
    right: 8%;
    max-width: 1088px;
    width: 58%;
  }

  &__sides-building {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  &__amazon {
    position: absolute;
    top: 30%;
    right: 22%;
    max-width: 303px;
    width: 16%;
  }

  &__cars {
    position: absolute;
    top: 50%;
    right: 15%;
    max-width: 764px;
    width: 40%;
    opacity: 0.15;
    mix-blend-mode: multiply;
  }

  &__info {
    position: absolute;
    top: 15%;
    left: 50%;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(-20%);
  }
}
