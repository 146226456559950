.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 10px;
  line-height: 12px;
  transform: translate(-50%, -50%);

  &__inner,
  &__outer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 3s;
  }

  &__inner {
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__outer {
    animation-name: rotatePlus;
    animation-duration: 4s;
  }

  &__inner {
    animation-name: rotateMinus;
  }

  @keyframes rotatePlus {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes rotateMinus {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
}