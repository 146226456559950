@import "./../../assets/styles/variables";

.mini-game {
   max-width: 550px;
   width: 100%;
   margin: 16px auto 0;
   padding: 0 15px 15px;
   text-align: center;

   &__btn-group {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 40px;

      .btn-game {
         &:not(:last-child) {
            margin-right: 40px;
         }
      }
   }

   &__title {
      margin: 0 0 20px 0;
   }

   &__text {
      margin: 0 0 10px 0;
      filter: drop-shadow(0 0 25px $blue);
   }
}
